import * as React from 'react'
import { useState, useRef } from 'react'
import { Box, Button, CssBaseline, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { Stack } from '@mui/system'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { DateRange } from '../../models/account_settings'
import {
  generateActivitySummary,
  generateBalanceSummary,
} from '../../redux/actions/account_settings'
import { APP_FONT } from '../../constants/app_font'
import { AppHeader } from '../../components/header/app_header'
import ReportModal from './reportModal'

const drawerWidth = 240

const Reporting: React.FC = () => {
  const dispatch = useDispatch()
  const [isReportGenerating, setIsReportGenerating] = useState(false)
  const [isReportGenerated, setIsReportGenerated] = useState(false)
  const [reportError, setReportError] = useState(false)
  const [generatedReportUrlPath, setGeneratedReportUrlPath] = useState('')
  const scrollRef = useRef<HTMLDivElement>(null)

  const currentDate = new Date()
  const oneDayAgo = new Date(currentDate.getTime() - 1 * 24 * 60 * 60 * 1000)
  const sevenDaysAgo = new Date(currentDate.getTime() - 8 * 24 * 60 * 60 * 1000)
  const formattedOneDayAgo = oneDayAgo
    .toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    })
    .replace(/(\d+)\/(\d+)\/(\d+),/, '$3-$1-$2T')
  const formattedSevenDaysAgo = sevenDaysAgo
    .toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    })
    .replace(/(\d+)\/(\d+)\/(\d+),/, '$3-$1-$2T')

  const [serviceActivityStartDate, setServiceActivityStartDate] = React.useState<Dayjs | null>(
    dayjs(formattedSevenDaysAgo)
  )

  const [serviceActivityEndDate, setServiceActivityEndDate] = React.useState<Dayjs | null>(
    dayjs(formattedOneDayAgo)
  )

  const handleServiceActivityStartDateChange = (newValue: Dayjs | null) => {
    setServiceActivityStartDate(newValue)
  }

  const handleServiceActivityEndDateChange = (newValue: Dayjs | null) => {
    setServiceActivityEndDate(newValue)
  }

  const handleGenerateActivityReport = () => {
    if (isReportGenerating) {
      return
    }

    setGeneratedReportUrlPath('')
    setIsReportGenerated(false)
    setIsReportGenerating(true)
    setReportError(false)

    const dateRange: DateRange = {
      start_date: serviceActivityStartDate?.format('YYYY-MM-DD') || '2024-01-01',
      end_date: serviceActivityEndDate?.format('YYYY-MM-DD') || '2024-01-01',
    }
    dispatch<any>(generateActivitySummary(dateRange))
      .then(unwrapResult)
      .then(async (response: any) => {
        setGeneratedReportUrlPath(response.key)
        setIsReportGenerating(false)
        setIsReportGenerated(true)
      })
      .catch((error: string) => {
        console.error('Error generating activity summary', error)
        setIsReportGenerating(false)
        setIsReportGenerated(true)
        setReportError(true)
      })
  }

  const handleGenerateBalanceReport = () => {
    if (isReportGenerating) {
      return
    }

    setGeneratedReportUrlPath('')
    setIsReportGenerated(false)
    setIsReportGenerating(true)
    setReportError(false)
    dispatch<any>(generateBalanceSummary())
      .then(unwrapResult)
      .then(async (response: any) => {
        setGeneratedReportUrlPath(response.key)
        setIsReportGenerating(false)
        setIsReportGenerated(true)
      })
      .catch((error: string) => {
        console.error('Error generating balance summary', error)
        setIsReportGenerating(false)
        setIsReportGenerated(true)
        setReportError(true)
      })
  }

  return (
    <Box sx={{ display: 'flex', pt: 10 }} ref={scrollRef}>
      <CssBaseline />
      <AppHeader />
      <Box
        component="main"
        sx={{
          paddingLeft: 6,
          paddingRight: 6,
          paddingTop: 12,
          paddingBottom: 4,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Typography fontWeight={700} fontFamily={APP_FONT} fontSize={32} color={'#FF6600'}>
          Reporting
        </Typography>

        {
          <Stack direction={'column'} spacing={2}>
            <Stack>
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: 3 }}>
                Balance summary
              </Typography>
              <Typography sx={{ marginTop: 1 }}>
                Track the financial transactions that led to your payouts to gain insight into your
                revenue through Carma.
              </Typography>
              <Button
                sx={{
                  borderRadius: 10,
                  fontSize: 16,
                  fontWeight: 700,
                  textTransform: 'none',
                  fontFamily: 'Figtree',
                  color: '#FFFFFF',
                  width: 200,
                  mt: 2,
                  backgroundColor: '#FF6600',
                }}
                color="warning"
                variant="contained"
                onClick={handleGenerateBalanceReport}
              >
                Generate report
              </Button>
            </Stack>
            <Stack>
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: 3 }}>
                Service activity summary
              </Typography>
              <Typography sx={{ marginTop: 1 }}>
                Understand the service activity breakdown in your Carma account between the start
                date and the end date.
              </Typography>
              <Stack sx={{ maxWidth: '450px', gap: 2, marginTop: 3 }} direction="row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Start date"
                    inputFormat="YYYY-MM-DD"
                    value={serviceActivityStartDate}
                    onChange={handleServiceActivityStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DesktopDatePicker
                    label="End date"
                    inputFormat="YYYY-MM-DD"
                    value={serviceActivityEndDate}
                    onChange={handleServiceActivityEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
              <Button
                sx={{
                  borderRadius: 10,
                  backgroundColor: '#FF6600',
                  fontSize: 16,
                  fontWeight: 700,
                  textTransform: 'none',
                  fontFamily: 'Figtree',
                  color: '#FFFFFF',
                  width: 200,
                  mt: 2,
                }}
                variant="contained"
                color="warning"
                onClick={handleGenerateActivityReport}
              >
                Generate report
              </Button>
            </Stack>
          </Stack>
        }
      </Box>
      {(isReportGenerating || isReportGenerated) && (
        <ReportModal
          isReportGenerating={isReportGenerating}
          isReportGenerated={isReportGenerated}
          reportUrlPath={isReportGenerating ? '' : generatedReportUrlPath}
          setIsOpen={isReportGenerating ? setIsReportGenerating : setIsReportGenerated}
          reportError={reportError}
        />
      )}
    </Box>
  )
}

export default Reporting
