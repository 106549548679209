import Decimal from 'decimal.js'
import { OfferServiceImage } from '../../../../redux/api/serviceApi/serviceApi.types'
import { sanitizeNumber } from '../../../../components/helper/helper'

type TirePositionsFieldsType = {
  LF_MEASUREMENT?: string
  LR_MEASUREMENT?: string
  RF_MEASUREMENT?: string
  RR_MEASUREMENT?: string
  LR_INSIDE_MEASUREMENT?: string
  RR_INSIDE_MEASUREMENT?: string
  TIRE_POSITIONS?: string[]
}

type TireInfoFieldsType = {
  TIRE_BRAND?: string | { name?: string }
  TIRE_MODEL?: string
  WARRANTY?: number
}

type CrankingAmpsFieldsType = {
  COLD_CRANKING_AMPS?: string
  FACTORY_COLD_CRANKING_AMPS?: string
}

type AxlesFieldsType = {
  LF_PAD?: string
  LR_PAD?: string
  RF_PAD?: string
  RR_PAD?: string
}

export type RecommendedServicePart = {
  name: string
  quantity: number | string
  price_per_unit: number | string
  number: string
}

export type RecommendedService = {
  id: string
  name: string
  // didn't change the name of type and types, because it seems to break things??
  type: string | null // serviceSubTypeId
  types: any[] // serviceSubTypes
  price: number
  serviceType: string
  labor_hours: number
  labor_price: number | string
  parts: RecommendedServicePart[]
  imageFiles: File[]
  uploadedImageFiles: OfferServiceImage[]
  additional_data:
    | (TirePositionsFieldsType & TireInfoFieldsType & CrankingAmpsFieldsType & AxlesFieldsType)
    | null
  uuid?: string
  offer_service_id?: string
}

export function GetServiceId(service: RecommendedService) {
  if (service.type !== null && service.type !== '') {
    return service.type
  }
  return service.id
}

export function updateServicesWithExistingImages(
  existingFiles: OfferServiceImage[],
  currentRecServices: RecommendedService[]
): RecommendedService[] {
  return currentRecServices.map((recService) => {
    const existingServiceImages = existingFiles.filter(
      (file: OfferServiceImage) => file.service_id === GetServiceId(recService)
    )
    return {
      ...recService,
      uploadedImageFiles: existingServiceImages,
    }
  })
}

export function GetTypeName(service: RecommendedService) {
  if (service.type !== null) {
    return service.types.find((type) => type.id === service.type)?.name
  }
  return null
}

export function GetServiceTotal(labor_rate: number, service: RecommendedService) {
  const partsTotal = service.parts.reduce((acc, part) => {
    const partTotal = new Decimal(sanitizeNumber(part.price_per_unit)).mul(part.quantity)
    return acc.plus(partTotal)
  }, new Decimal(0))

  const laborTotal = new Decimal(sanitizeNumber(service.labor_hours)).mul(labor_rate)

  const total = partsTotal.plus(laborTotal)

  return total.toDecimalPlaces(2).toNumber()
}
