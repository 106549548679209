import { Button, Group, LoadingOverlay, Stack, Text } from '@mantine/core'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Button as MuiButton,
  Typography,
} from '@mui/material'
import { IconCloudUpload, IconRefresh } from '@tabler/icons-react'
import React, { memo, useCallback, useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import SuccessImg from '../../../../../assets/images/success.png'
import { MuiInputWrapper } from '../../../../../components/MuiInputWrapper'
import { APP_FONT } from '../../../../../constants/app_font'
import { useDeleteRecServiceImageMutation } from '../../../../../redux/api/serviceApi/serviceApi'
import { OfferServiceImage } from '../../../../../redux/api/serviceApi/serviceApi.types'

type RecServicePhotoUploadProps = {
  serviceName: string
  onFilesChange: (files: File[]) => void
  onRefetchUploadedImages: () => void
  serviceId: string
  offerId: string
  files: File[]
  existingFiles: OfferServiceImage[]
  error?: string
}

export const RecServicePhotoUpload = ({
  serviceName,
  onFilesChange,
  onRefetchUploadedImages,
  files,
  serviceId,
  offerId,
  existingFiles,
  error,
}: RecServicePhotoUploadProps) => {
  const [open, setOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const qrCodeUploadUrl = `${process.env.REACT_APP_CUSTOMER_APP_URL}/provider-qrUpload?serviceId=${serviceId}&offerId=${offerId}`

  const [deleteImage] = useDeleteRecServiceImageMutation()

  const onDelete = async (imageId: string) => {
    try {
      await deleteImage({
        service_id: serviceId,
        offer_id: offerId,
        image_id: imageId,
      }).unwrap()
    } catch (e) {
      console.error(e)
    }
  }

  const appendFiles = useCallback(
    (newFiles: File[]) => {
      onFilesChange([...files, ...newFiles])
    },
    [onFilesChange, files]
  )

  const handleUploadClicked = useCallback(() => {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = true
    input.accept = 'image/png, image/jpeg, image/heic, image/jpg'
    input.addEventListener('change', (e) => {
      const fileList = (e.target as HTMLInputElement).files
      if (!fileList) {
        return
      }
      const newFiles = Array.from(fileList)
      appendFiles(newFiles)
    })
    document.body.appendChild(input)
    input.click()
  }, [appendFiles])

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      // accept only PNG, JPEG, JPG, HEIC file types

      if (e.dataTransfer.items) {
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          if (
            e.dataTransfer.items[i].kind === 'file' &&
            e.dataTransfer.items[i].type.match('^image/')
          ) {
            continue
          }
          return
        }
      }

      const files = Array.from(e.dataTransfer.files)
      appendFiles(files)
    },
    [appendFiles]
  )

  const onRemove = useCallback(
    (index: number) => {
      onFilesChange(files.filter((_, i) => i !== index))
    },
    [files, onFilesChange]
  )

  const onSubmit = () => {
    setIsSubmitted(true)
    setOpen(false)
  }

  const hasUploadedImages = existingFiles.length > 0 || files.length > 0

  return (
    <>
      <MuiInputWrapper error={error} mt={20} mb={20}>
        <Group
          onClick={() => {
            onRefetchUploadedImages()
            setOpen(true)
          }}
          align="center"
          justify="space-between"
          bd={error ? '1px solid red' : '1px solid #ffc9b3'}
          p="12px 8px"
          style={{
            cursor: 'pointer',
            borderRadius: '8px',
          }}
        >
          <Group gap={12}>
            {hasUploadedImages ? (
              <img
                style={{
                  height: 24,
                  width: 24,
                }}
                src={SuccessImg}
                alt="uploaded"
              />
            ) : null}
            <Stack gap={0}>
              <Text size="lg" fw={700}>
                PHOTO UPLOAD REQUIRED
              </Text>
              <Text size="sm" fw={500}>
                Upload a high-resolution photo CLEARLY showing the issue.
              </Text>
            </Stack>
          </Group>
          <Text
            onClick={onRefetchUploadedImages}
            size="md"
            fw={700}
            c="#ff6600"
            style={{
              textDecoration: 'underline',
            }}
          >
            {isSubmitted ? 'See your files' : 'Click to upload'}
          </Text>
        </Group>
      </MuiInputWrapper>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          style: {
            padding: 20,
            maxHeight: '90vh',
          },
        }}
      >
        <Group justify="space-between" pl={15} pr={15}>
          <Stack gap={1}>
            <Text size={'26px'} fw={700}>
              Photo upload
            </Text>
            <Text size="lg">Upload a high-resolution photo CLEARLY showing the issue.</Text>
          </Stack>
          <IconButton
            aria-label="close"
            color="inherit"
            size="large"
            onClick={() => {
              setOpen(false)
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Group>
        <DialogContent
          style={{ paddingLeft: 15, paddingRight: 15, overflow: 'auto', maxHeight: '80vh' }}
        >
          <Group gap={2} mb={20}>
            <Text size="lg">Service requiring photo upload:</Text>
            <Text size="lg" c="#ff6600" fw={700}>
              {serviceName}
            </Text>
          </Group>
          <Group justify="center" align="center" gap={50}>
            <Stack gap={15}>
              <Text w={400} size="lg" fw={700}>
                You can upload photos from your COMPUTER
              </Text>
              <Box
                onDragOver={(event) => event.preventDefault()}
                onDrop={onDrop}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  border: '2px dashed #C4C4C4',
                  borderRadius: '4px',
                  padding: '16px',
                  marginBottom: '16px',
                  transition: 'background-color 0.3s',
                  backgroundColor: '#FAFAFA',
                  width: '500px',
                  height: '150px',
                }}
              >
                <IconCloudUpload style={{ fontSize: '50px', color: '#C4C4C4' }} />
                <Typography>
                  <MuiButton
                    variant="text"
                    component="span"
                    style={{ textTransform: 'none', padding: 0, fontSize: '16px', fontWeight: 700 }}
                    color="warning"
                    onClick={handleUploadClicked}
                  >
                    Click to upload
                  </MuiButton>{' '}
                  or drag and drop
                </Typography>
                <Text size="md">
                  Supported files: <b>PNG, JPG, JPEG or HEIC</b> (max 50 MB)
                </Text>
              </Box>
            </Stack>
            {serviceId && offerId ? (
              <Stack align="center" gap={0}>
                <Text w={450} size="lg" fw={700}>
                  OR you can also upload photos from your PHONE
                </Text>
                <Text w={450} mb={10}>
                  Scan the QR code with your phone to get started.
                </Text>
                <QRCode value={qrCodeUploadUrl} size={150} />
              </Stack>
            ) : null}
          </Group>
          <div>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginTop: '24px',
                marginBottom: '10px',
              }}
            >
              <Text size="xl" fw={700}>
                {hasUploadedImages ? 'Uploaded files' : 'No files uploaded'}
              </Text>
              <Button
                leftSection={<IconRefresh size={18} />}
                variant="filled"
                color="orange"
                onClick={onRefetchUploadedImages}
                style={{ height: '25px', fontFamily: APP_FONT }}
              >
                Refresh the photo list
              </Button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              {existingFiles.map((file) => (
                <FileItem key={file.id} img={file} onRemove={() => onDelete(file.id)} />
              ))}
              {files.map((file, index) => (
                <FileItem key={file.name} file={file} onRemove={() => onRemove(index)} />
              ))}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
            }}
          >
            <MuiButton
              variant="contained"
              color="warning"
              onClick={onSubmit}
              sx={{
                fontFamily: APP_FONT,
                margin: '0 auto',
                width: 'fit-content',
                borderRadius: '20px',
                textTransform: 'none',
                fontWeight: 'bold',
                paddingLeft: '32px',
                paddingRight: '32px',
                fontSize: '18px',
              }}
            >
              Save all photos
            </MuiButton>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

const FileItem = memo(
  ({ file, onRemove, img }: { file?: File; img?: OfferServiceImage; onRemove: () => void }) => {
    const [convertedUrl, setConvertedUrl] = useState<string>()
    const [loading, setLoading] = useState(true)

    // Wrap in useEffect to use async/await since HEIC conversion is slow
    useEffect(() => {
      const convertAndSetUrl = async () => {
        if (file?.type === 'image/heic') {
          try {
            // Dynamically import this fat package
            const heic2any = (await import('heic2any')).default
            const converted = await heic2any({ blob: file, toType: 'image/jpeg' })
            const url = URL.createObjectURL(Array.isArray(converted) ? converted[0] : converted)
            setConvertedUrl(url)
          } catch (error) {
            console.error('Error converting image:', error)
          } finally {
            setLoading(false)
          }
        } else if (file) {
          setConvertedUrl(URL.createObjectURL(file))
          setLoading(false)
        } else {
          setConvertedUrl(img?.url)
          setLoading(false)
        }
      }

      convertAndSetUrl()

      return () => {
        if (convertedUrl) {
          URL.revokeObjectURL(convertedUrl)
        }
      }
    }, [file, img])
    return (
      <div
        style={{
          display: 'flex',
          border: '1px solid #eaecf0',
          borderRadius: 12,
          padding: 12,
          position: 'relative',
        }}
      >
        <LoadingOverlay visible={loading} />
        <img
          src={convertedUrl}
          alt={file?.name || img?.name}
          style={{
            width: 100,
            height: 70,
            objectFit: 'cover',
            borderRadius: 12,
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{
            flex: 1,
            marginLeft: 12,
          }}
        >
          <Typography sx={{ fontFamily: APP_FONT }}>{file?.name || img?.name}</Typography>
        </Box>
        <IconButton aria-label="close" color="inherit" size="medium" onClick={onRemove}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
    )
  }
)
