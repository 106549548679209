import { Decimal } from 'decimal.js'

export function formatPrice(price: string | number | Decimal) {
  const numericPrice = price instanceof Decimal ? price.toNumber() : sanitizeNumber(price)
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(numericPrice)
}

export function sanitizeNumber(value: string | number): number {
  if (typeof value === 'string') {
    // this also works for values that would evaluate to NaN
    // for mantine number inputs, '.' and '' are examples that would evaluate to NaN
    return parseFloat(value.replace(/,/g, '')) || 0
  }
  return value || 0
}
