import { FlattenedError } from '../../../../components/ErrorSummary'
import { sanitizeNumber } from '../../../../components/helper/helper'
import { Service } from './inspection_upload/inspection_upload'
import { OfferBlockService } from './offer_create/OfferServiceBlock'
import { RecommendedService } from './service.model'

type AxlePadsErrors = {
  LF_PAD?: FormError
  RF_PAD?: FormError
  LR_PAD?: FormError
  RR_PAD?: FormError
}

const validateAxlePads = (
  additionalData: any,
  frontAxle: boolean,
  rearAxle: boolean
): { isValid: boolean; errors: AxlePadsErrors } => {
  let isValid = true
  let errors: AxlePadsErrors = {}
  if (frontAxle && (!additionalData.LF_PAD || additionalData.LF_PAD === '')) {
    errors.LF_PAD = { message: 'Enter the measurement' }
    isValid = false
  }
  if (frontAxle && (!additionalData.RF_PAD || additionalData.RF_PAD === '')) {
    errors.RF_PAD = { message: 'Enter the measurement' }
    isValid = false
  }
  if (rearAxle && (!additionalData.LR_PAD || additionalData.LR_PAD === '')) {
    errors.LR_PAD = { message: 'Enter the measurement' }
    isValid = false
  }
  if (rearAxle && (!additionalData.RR_PAD || additionalData.RR_PAD === '')) {
    errors.RR_PAD = { message: 'Enter the measurement' }
    isValid = false
  }

  return { isValid, errors }
}

export const tirePositionsToMeasurementMapping = {
  LR: 'LR_MEASUREMENT',
  RR: 'RR_MEASUREMENT',
  LF: 'LF_MEASUREMENT',
  RF: 'RF_MEASUREMENT',
  LR_INSIDE: 'LR_INSIDE_MEASUREMENT',
  RR_INSIDE: 'RR_INSIDE_MEASUREMENT',
}

export const tirePositionMeasurementReadableNameMapping = {
  LR_MEASUREMENT: 'LR Measurement',
  RR_MEASUREMENT: 'RR Measurement',
  LF_MEASUREMENT: 'LF Measurement',
  RF_MEASUREMENT: 'RF Measurement',
  LR_INSIDE_MEASUREMENT: 'LR Inside Measurement',
  RR_INSIDE_MEASUREMENT: 'RR Inside Measurement',
}

type TireDataErrors = {
  TIRE_POSITIONS?: FormError
  LR_MEASUREMENT?: FormError
  RR_MEASUREMENT?: FormError
  LF_MEASUREMENT?: FormError
  RF_MEASUREMENT?: FormError
  LR_INSIDE_MEASUREMENT?: FormError
  RR_INSIDE_MEASUREMENT?: FormError
  TIRE_BRAND?: FormError
  TIRE_MODEL?: FormError
  WARRANTY?: FormError
}

const validateTireData = (
  additionalData: {
    TIRE_POSITIONS?: any[]
    TIRE_BRAND?: string
    TIRE_MODEL?: string
    WARRANTY?: number
  },
  tirePositions: boolean,
  tireInfo: boolean
): { isValid; errors: TireDataErrors } => {
  let isValid = true
  let errors: TireDataErrors = {}
  if (
    tirePositions &&
    (!Array.isArray(additionalData.TIRE_POSITIONS) || additionalData.TIRE_POSITIONS.length === 0)
  ) {
    errors.TIRE_POSITIONS = { message: 'Select at least one tire' }
    isValid = false
  }

  if (tirePositions && additionalData.TIRE_POSITIONS) {
    // todo: validate that tire positions are valid options
    for (const tire of additionalData.TIRE_POSITIONS) {
      const tireMeasurementKey = tirePositionsToMeasurementMapping[tire]
      if (!additionalData[tireMeasurementKey]) {
        errors[tireMeasurementKey] = {
          message: `Enter the ${tirePositionMeasurementReadableNameMapping[tireMeasurementKey]}`,
        }
        isValid = false
      }
    }
  }

  if (tireInfo) {
    if (additionalData.TIRE_BRAND === undefined || additionalData.TIRE_BRAND === '') {
      errors.TIRE_BRAND = { message: 'Enter the tire brand' }
      isValid = false
    }
    if (additionalData.TIRE_MODEL === undefined || additionalData.TIRE_MODEL === '') {
      errors.TIRE_MODEL = { message: 'Enter the tire model' }
      isValid = false
    }
  }

  return { isValid, errors }
}

type CrankingAmpsErrors = {
  COLD_CRANKING_AMPS?: FormError
  FACTORY_COLD_CRANKING_AMPS?: FormError
}

const validateCrankingAmps = (
  additionalData: any,
  crankingAmps: boolean
): { isValid: boolean; errors: CrankingAmpsErrors } => {
  let isValid = true
  let errors: CrankingAmpsErrors = {}
  if (
    crankingAmps &&
    (!additionalData.COLD_CRANKING_AMPS || additionalData.COLD_CRANKING_AMPS <= 0)
  ) {
    errors.COLD_CRANKING_AMPS = { message: 'Enter the cold cranking amps' }
    isValid = false
  }
  if (
    crankingAmps &&
    (!additionalData.FACTORY_COLD_CRANKING_AMPS || additionalData.FACTORY_COLD_CRANKING_AMPS <= 0)
  ) {
    errors.FACTORY_COLD_CRANKING_AMPS = {
      message: 'Enter the factory cold cranking amps',
    }
    isValid = false
  }
  return { isValid, errors }
}

const validatePart = (part: {
  name?: string
  number?: string
  quantity?: any
  price_per_unit: string | number
}): { isValid: boolean; errors: PartErrors } => {
  const errors: PartErrors = {}
  let isValid = true
  if (!part.name) {
    errors.name = { message: 'Enter the part name' }
    isValid = false
  }
  if (!part.number) {
    errors.number = { message: 'Enter the part number' }
    isValid = false
  }
  if (part.quantity <= 0) {
    errors.quantity = { message: 'Quantity should be at least 1' }
    isValid = false
  }
  if (part.price_per_unit === '' || part.price_per_unit === '.') {
    errors.price_per_unit = { message: 'Enter the price per unit' }
    isValid = false
  } else if (sanitizeNumber(part.price_per_unit) < 0) {
    errors.price_per_unit = { message: 'Price must be at least 0' }
    isValid = false
  }
  return { isValid, errors }
}

const validateParts = (
  parts: { name?: string; quantity?: any; price_per_unit: string | number; number?: string }[]
): { isValid: boolean; errors: PartErrors[] } => {
  if (!parts || parts.length === 0) {
    return { isValid: true, errors: [] }
  }
  const results = parts.map((part) => validatePart(part))
  return {
    isValid: results.every(({ isValid }) => isValid),
    errors: results.map(({ errors: singlePartErrors }) => singlePartErrors),
  }
}

export type FormError = {
  message: string
}

export type PartErrors = {
  name?: FormError
  number?: FormError
  quantity?: FormError
  price_per_unit?: FormError
}

export const flattenPartErrors = (errors: PartErrors): FlattenedError[] => {
  const flattenedErrors: FlattenedError[] = []
  if (errors.name) {
    flattenedErrors.push({ field: 'name', message: errors.name.message })
  }
  if (errors.number) {
    flattenedErrors.push({ field: 'number', message: errors.number.message })
  }
  if (errors.quantity) {
    flattenedErrors.push({ field: 'quantity', message: errors.quantity.message })
  }
  if (errors.price_per_unit) {
    flattenedErrors.push({ field: 'price per unit', message: errors.price_per_unit.message })
  }
  return flattenedErrors
}

export type AdditionalDataErrors = TireDataErrors & CrankingAmpsErrors & AxlePadsErrors

export const flattenAdditionalDataErrors = (errors: AdditionalDataErrors): FlattenedError[] => {
  const flattenedErrors: FlattenedError[] = []
  if (errors.TIRE_POSITIONS) {
    flattenedErrors.push({ field: 'tire positions', message: errors.TIRE_POSITIONS.message })
  }
  if (errors.LR_MEASUREMENT) {
    flattenedErrors.push({ field: 'LR measurement', message: errors.LR_MEASUREMENT.message })
  }
  if (errors.RR_MEASUREMENT) {
    flattenedErrors.push({ field: 'RR measurement', message: errors.RR_MEASUREMENT.message })
  }
  if (errors.LF_MEASUREMENT) {
    flattenedErrors.push({ field: 'LF measurement', message: errors.LF_MEASUREMENT.message })
  }
  if (errors.RF_MEASUREMENT) {
    flattenedErrors.push({ field: 'RF measurement', message: errors.RF_MEASUREMENT.message })
  }
  if (errors.LR_INSIDE_MEASUREMENT) {
    flattenedErrors.push({
      field: 'LR inside measurement',
      message: errors.LR_INSIDE_MEASUREMENT.message,
    })
  }
  if (errors.RR_INSIDE_MEASUREMENT) {
    flattenedErrors.push({
      field: 'RR inside measurement',
      message: errors.RR_INSIDE_MEASUREMENT.message,
    })
  }
  if (errors.TIRE_BRAND) {
    flattenedErrors.push({ field: 'tire brand', message: errors.TIRE_BRAND.message })
  }
  if (errors.TIRE_MODEL) {
    flattenedErrors.push({ field: 'tire model', message: errors.TIRE_MODEL.message })
  }
  if (errors.WARRANTY) {
    flattenedErrors.push({ field: 'warranty', message: errors.WARRANTY.message })
  }
  if (errors.COLD_CRANKING_AMPS) {
    flattenedErrors.push({
      field: 'cold cranking amps',
      message: errors.COLD_CRANKING_AMPS.message,
    })
  }
  if (errors.FACTORY_COLD_CRANKING_AMPS) {
    flattenedErrors.push({
      field: 'factory cold cranking amps',
      message: errors.FACTORY_COLD_CRANKING_AMPS.message,
    })
  }
  if (errors.LF_PAD) {
    flattenedErrors.push({ field: 'LF pad', message: errors.LF_PAD.message })
  }
  if (errors.RF_PAD) {
    flattenedErrors.push({ field: 'RF pad', message: errors.RF_PAD.message })
  }
  if (errors.LR_PAD) {
    flattenedErrors.push({ field: 'LR pad', message: errors.LR_PAD.message })
  }
  if (errors.RR_PAD) {
    flattenedErrors.push({ field: 'RR pad', message: errors.RR_PAD.message })
  }
  return flattenedErrors
}

export type ServiceFormErrors = {
  labor_price?: FormError
  parts: PartErrors[]
  name?: FormError
  type?: FormError
  imageFiles?: FormError
  additional_data: AdditionalDataErrors
}

export const flattenServiceFormErrors = (
  errors: ServiceFormErrors
): { field: string; message: string }[] => {
  const partsErrors = errors.parts
    .map((partErrors, index) => {
      return flattenPartErrors(partErrors).map((error) => ({
        message: error.message,
        field: `part ${index + 1} ${error.field}`,
      }))
    })
    .flat()
  const additionalDataErrors = flattenAdditionalDataErrors(errors.additional_data)
  return [
    ...(errors.name ? [{ field: 'name', message: errors.name.message }] : []),
    ...(errors.type ? [{ field: 'type', message: errors.type.message }] : []),
    ...additionalDataErrors,
    ...(errors.labor_price ? [{ field: 'labor price', message: errors.labor_price.message }] : []),
    ...partsErrors,
    ...(errors.imageFiles ? [{ field: 'image', message: errors.imageFiles.message }] : []),
  ]
}

export const validateRecommendedServiceFields = (
  services: Service[],
  service: RecommendedService
): { isValid: boolean; errors: ServiceFormErrors } => {
  const additionalData = (service.additional_data as Partial<any>) || {}

  const selectedService =
    service.types.find((s) => s.id === service.type) || services.find((s) => s.id === service.id)

  const errors: ServiceFormErrors = { parts: [], additional_data: {} }
  let isValid = true
  if (service.id === '') {
    errors.name = { message: 'Select the service' }
    isValid = false
  }

  if (service.types.length > 0 && service.type === null) {
    errors.type = { message: 'Select the service type' }
    isValid = false
  }

  const recommendedRequiredData = selectedService?.recommend_required_data || []
  const isTirePositions = recommendedRequiredData.includes('TIRE_POSITIONS')
  const isTireInfo = recommendedRequiredData.includes('TIRE_BRAND')
  const isCrankingAmps = recommendedRequiredData.includes('COLD_CRANKING_AMPS')
  const isFrontAxle =
    recommendedRequiredData.includes('LF_PAD') && recommendedRequiredData.includes('RF_PAD')
  const isRearAxle =
    recommendedRequiredData.includes('LR_PAD') && recommendedRequiredData.includes('RR_PAD')

  const { isValid: arePartsValid, errors: partErrors } = validateParts(service.parts)
  if (arePartsValid === false) {
    isValid = false
  }
  errors.parts = partErrors

  if (service.labor_price === '' || service.labor_price === '.') {
    errors.labor_price = { message: 'Enter the labor price' }
    isValid = false
  }

  const { isValid: isTireDataValid, errors: tireDataErrors } = validateTireData(
    additionalData,
    isTirePositions,
    isTireInfo
  )
  if (isTireDataValid === false) {
    isValid = false
  }
  errors.additional_data = tireDataErrors

  const { isValid: areCrankingAmpsValid, errors: crankingAmpsErrors } = validateCrankingAmps(
    additionalData,
    isCrankingAmps
  )
  if (areCrankingAmpsValid === false) {
    isValid = false
  }
  errors.additional_data = { ...errors.additional_data, ...crankingAmpsErrors }

  const { isValid: areAxlePadsValid, errors: axlePadsErrors } = validateAxlePads(
    additionalData,
    isFrontAxle,
    isRearAxle
  )
  if (areAxlePadsValid === false) {
    isValid = false
  }
  errors.additional_data = { ...errors.additional_data, ...axlePadsErrors }

  if (
    !service.imageFiles ||
    (service.imageFiles.length === 0 &&
      (!service.uploadedImageFiles || service.uploadedImageFiles.length === 0))
  ) {
    errors.imageFiles = { message: 'Upload at least one image' }
    isValid = false
  }

  return { isValid, errors }
}

export const validateOfferServiceFields = (
  service: OfferBlockService,
  parentService: any
): { isValid: boolean; errors: ServiceFormErrors } => {
  const errors: ServiceFormErrors = { parts: [], additional_data: {} }
  let isValid = true
  const { isValid: arePartsValid, errors: partErrors } = validateParts(service.parts)
  if (arePartsValid === false) {
    isValid = false
  }

  errors.parts = partErrors

  if (service.labor_price === '' || service.labor_price === '.') {
    errors.labor_price = { message: 'Enter the labor price' }
    isValid = false
  }

  const serviceChild = parentService?.child
  const offer_required_data =
    serviceChild?.offer_required_data || parentService?.offer_required_data || []

  const additionalData = (service.additional_data as Partial<any>) || {}

  const isTirePositions = offer_required_data.includes('TIRE_POSITIONS')
  const isTireInfo = offer_required_data.includes('TIRE_BRAND')
  const isCrankingAmps = offer_required_data.includes('COLD_CRANKING_AMPS')
  const isFrontAxle =
    offer_required_data.includes('LF_PAD') && offer_required_data.includes('RF_PAD')
  const isRearAxle =
    offer_required_data.includes('LR_PAD') && offer_required_data.includes('RR_PAD')

  const { isValid: isTireDataValid, errors: tireDataErrors } = validateTireData(
    additionalData,
    isTirePositions,
    isTireInfo
  )
  if (isTireDataValid === false) {
    isValid = false
  }
  errors.additional_data = tireDataErrors

  const { isValid: areCrankingAmpsValid, errors: crankingAmpsErrors } = validateCrankingAmps(
    additionalData,
    isCrankingAmps
  )
  if (areCrankingAmpsValid === false) {
    isValid = false
  }
  errors.additional_data = { ...errors.additional_data, ...crankingAmpsErrors }

  const { isValid: areAxlePadsValid, errors: axlePadsErrors } = validateAxlePads(
    additionalData,
    isFrontAxle,
    isRearAxle
  )
  if (areAxlePadsValid === false) {
    isValid = false
  }
  errors.additional_data = { ...errors.additional_data, ...axlePadsErrors }

  return { isValid, errors }
}
