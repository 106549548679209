import React, { useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  Paper,
  IconButton,
  styled,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import socket from '../../../sockets/socket'
import { APP_FONT } from '../../../constants/app_font'
import { ReactComponent as CurrentOffersIcon } from '../../../assets/images/current_offers_icon.svg'
import HoverPopper from '../../../components/hover/hover_popper'
import { formatDateTime } from '../../../utils/helper'
import { fetchOffers } from '../../../redux/actions/offer'
import useLocalTimezoneDates from '../../../utils/localizeTimezone'
import Copy from '../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../assets/images/contentCopyFilled.svg'
import { Offer } from '../../../models/offer_new'
import { InfoChip } from './modals/components/ui/info_chip'
import { ArrowTooltip } from './modals/components/ui/arrow_tooltip'
import { formatPrice } from '../../../components/helper/helper'
import ServiceList from '../components/service_list'
import InvoiceNumberCellText from '../components/invoice_number_cell_text'
import { Stack } from '@mantine/core'

interface Data {
  // AutomatedImg: string
  RequestID: string
  InvoiceNumber: string
  Vehicle: string
  VehicleId: string
  VIN: string
  VehicleClass: string
  EngineType: string
  EngineSize: string
  Services: string
  OfferAmount: string
  Timeblock: string
  OfferPool: string
  PickupTime: string
  Comments: string
  SpecialHandling: string
  EditAction: string
}

const StickyHeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  top: 0,
  zIndex: 10,
  position: 'sticky',
  boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)',
}))

const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
    margin: 12px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
    margin: 12px;
  }
`

const rows = []

type Order = 'asc' | 'desc'

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'RequestID',
    disablePadding: true,
    label: 'Request ID',
  },
  {
    id: 'InvoiceNumber',
    disablePadding: false,
    label: 'Invoice #',
  },
  {
    id: 'Vehicle',
    disablePadding: false,
    label: 'Vehicle',
  },
  {
    id: 'VehicleId',
    disablePadding: false,
    label: 'Vehicle ID',
  },
  {
    id: 'VIN',
    disablePadding: false,
    label: 'VIN',
  },
  {
    id: 'VehicleClass',
    disablePadding: false,
    label: 'Vehicle class',
  },
  {
    id: 'EngineType',
    disablePadding: false,
    label: 'Engine type',
  },
  {
    id: 'EngineSize',
    disablePadding: false,
    label: 'Engine size',
  },
  {
    id: 'Services',
    disablePadding: false,
    label: 'Services',
  },
  {
    id: 'OfferAmount',
    disablePadding: false,
    label: 'Offer amount',
  },
  {
    id: 'Timeblock',
    disablePadding: false,
    label: 'Drop-off time',
  },
  {
    id: 'PickupTime',
    disablePadding: false,
    label: 'Pick up time',
  },
  {
    id: 'Comments',
    disablePadding: false,
    label: 'Comments',
  },
  {
    id: 'SpecialHandling',
    disablePadding: false,
    label: 'Special handling requirements',
  },
]

const getDropOffDateTime = (service_request: any) => {
  if (
    !service_request.drop_off_date ||
    !service_request.drop_off_time ||
    service_request.drop_off_time.length === 0
  ) {
    console.error('Invalid drop-off date or time')
    return null
  }
  const [datePart] = service_request.drop_off_date.split('T')
  const timeStr = service_request.drop_off_time[0].split(' ')[0]

  const [year, month, day] = datePart.split('-').map((num: any) => parseInt(num, 10))
  const period = service_request.drop_off_time[0].split(' ')[1]
  let [hours, minutes] = timeStr.split(':').map((str: string) => parseInt(str, 10))

  if (period === 'PM' && hours < 12) hours += 12
  if (period === 'AM' && hours === 12) hours = 0

  const dropOffDate = new Date(year, month - 1, day, hours, minutes, 0, 0)

  return dropOffDate.toISOString()
}

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

type Props = {
  formatCurrency: any
}

export default function CurrentOffers(props: Props) {
  // const { formatCurrency } = props
  const [order] = useState<Order>('asc')
  const [orderBy] = useState<keyof Data>('RequestID')
  const [selected] = useState<readonly string[]>([])
  const [currentOffers, setCurrentOffers] = useState<Offer[]>([])
  // const [selectedOffer, setSelectedOffer] = useState<Offer | null>()
  const dispatch = useDispatch()
  // const [open, setOpen] = useState(false)
  const [copiedVin, setCopiedVin] = React.useState<string | null>(null)
  const [copiedComment, setCopiedComment] = React.useState<string | null>(null)
  // const [editModalOpen, setEditModalOpen] = useState(false)
  // const [editingOffer, setEditingOffer] = useState<Offer | null>(null)
  // const [alert, setAlert] = useState(false)

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy } = props
    const hasDiagnostic = currentOffers.some(
      (offer) => offer.consumer_request.type === 'DIAGNOSTIC'
    )

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            if (headCell.id === 'EditAction' && hasDiagnostic) {
              return null
            }
            return (
              <StickyHeaderTableCell
                key={headCell.id}
                align={'left'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <div style={{ fontWeight: '600', fontFamily: APP_FONT }}>{headCell.label}</div>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </StickyHeaderTableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      </Toolbar>
    )
  }

  const localCurrentOffers = useLocalTimezoneDates(currentOffers, [
    'provider_drop_off_by',
    'drop_off_by',
    'pick_up_after',
  ])

  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => {
          setCopiedVin(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyComment = (event: React.MouseEvent, comment: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(comment)
      .then(() => {
        setCopiedComment(comment)
        setTimeout(() => {
          setCopiedComment(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const getCurrentOffers = () => {
    const providerId = localStorage.getItem('branchId')
    if (providerId)
      dispatch<any>(fetchOffers({ statuses: ['REQUESTED'], providerId }))
        .then(unwrapResult)
        .then((offers: any) => {
          setCurrentOffers(offers)
          const mappedOffers = offers.map((offer: Offer) => {
            return {
              ...offer,
              requestedDateTime: getDropOffDateTime(offer.consumer_request),
            }
          })
          setCurrentOffers(mappedOffers)
        })
        .catch((rejectedValueOrSerializedError: any) => {
          console.log('error')
        })
  }

  React.useEffect(() => {
    getCurrentOffers()

    const providerId = localStorage.getItem('branchId')
    if (providerId) {
      socket.emit('subscribe', { branchId: providerId })
      socket.on('status-update', (payload) => {
        getCurrentOffers()
      })
    }

    return () => {
      socket.off('status-update')
    }
  }, [])

  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          marginLeft: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: -8,
        }}
      >
        <CurrentOffersIcon />
        <Typography
          sx={{
            marginTop: 4,
            fontFamily: APP_FONT,
            fontSize: 16,
            letterSpacing: 1,
            textTransform: 'uppercase',
          }}
          id="tableTitle"
          component="div"
        >
          Current Offers
        </Typography>
      </Box>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer sx={{ px: 2 }}>
          <Table aria-labelledby="tableTitle" size={'small'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={rows.length}
              onRequestSort={function (
                event: React.MouseEvent<unknown, MouseEvent>,
                property: keyof Data
              ): void {
                throw new Error('Function not implemented.')
              }}
              onSelectAllClick={function (event: React.ChangeEvent<HTMLInputElement>): void {
                throw new Error('Function not implemented.')
              }}
            />
            <TableBody>
              {localCurrentOffers
                ? localCurrentOffers.map((row: Offer) => (
                    <TableRow key={row.id}>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.consumer_request.slug ?? row.consumer_request.id}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <InvoiceNumberCellText invoiceNumber={row.invoice_number} />
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {`${row.vehicle?.year} ${row.vehicle?.make} ${row.vehicle?.model} ${
                          row.vehicle?.trim ?? ''
                        }` || 'Null'}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }}>
                        <div style={{ maxWidth: '125px' }}>{row.vehicle?.name}</div>
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                          {row.vehicle?.vin || 'VIN not provided'}
                          {row.vehicle?.vin && (
                            <ArrowTooltip
                              style={{ margin: 0 }}
                              title={copiedVin === row.vehicle?.vin ? 'Copied!' : ''}
                            >
                              <StyledButton
                                onClick={(event) => handleCopyVin(event, row.vehicle?.vin ?? '')}
                              >
                                <img
                                  src={copiedVin === row.vehicle?.vin ? CopyFilled : Copy}
                                  alt=""
                                />
                              </StyledButton>
                            </ArrowTooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.vehicle?.class?.name ? row.vehicle?.class?.name : ''}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {[row.vehicle?.engines].join(', ')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.vehicle?.engine_size}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <ServiceList offerOrRequest={row} />
                      </TableCell>
                      <TableCell style={{ fontFamily: APP_FONT }} align="left">
                        {!isNaN(
                          Number(row.new_supply_fee ? row.new_supply_fee : row.supply_fee) +
                            Number(row.new_tax ? row.new_tax : row.tax) +
                            row.services.reduce(
                              (total, service) =>
                                total + (service.price ? parseFloat(service.price) : 0),
                              0
                            )
                        )
                          ? formatPrice(
                              Number(row.new_supply_fee ? row.new_supply_fee : row.supply_fee) +
                                Number(row.new_tax ? row.new_tax : row.tax) +
                                row.services.reduce(
                                  (total, service) =>
                                    total + (service.price ? parseFloat(service.price) : 0),
                                  0
                                )
                            )
                          : Number(row.total_fee)}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.provider_drop_off_by
                          ? formatDateTime(row.provider_drop_off_by)
                          : formatDateTime(row.drop_off_by) || 'Not Selected'}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {formatDateTime(row.pick_up_after) || 'Not Selected'}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            maxWidth: '125px',
                          }}
                        >
                          {row.consumer_request.additional_information ? (
                            <>
                              {row.consumer_request.additional_information.length > 104 ? (
                                <HoverPopper
                                  holder={
                                    <span>
                                      {row.consumer_request.additional_information.substring(0, 20)}
                                      <span>...</span>
                                    </span>
                                  }
                                >
                                  <Box
                                    sx={{
                                      width: '500px',
                                      textAlign: 'center',
                                      backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                      pointerEvents: 'auto',
                                      p: 2,
                                    }}
                                  >
                                    {row.consumer_request.additional_information}
                                  </Box>
                                </HoverPopper>
                              ) : (
                                row.consumer_request.additional_information
                              )}
                              <ArrowTooltip
                                style={{ margin: 0 }}
                                title={
                                  copiedComment === row.consumer_request.additional_information
                                    ? 'Copied!'
                                    : ''
                                }
                              >
                                <StyledButton
                                  onClick={(e: any) =>
                                    handleCopyComment(
                                      e,
                                      row.consumer_request.additional_information ?? ''
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      copiedComment === row.consumer_request.additional_information
                                        ? CopyFilled
                                        : Copy
                                    }
                                    alt="Copy icon"
                                  />
                                </StyledButton>
                              </ArrowTooltip>
                            </>
                          ) : (
                            'No additional information'
                          )}
                        </div>
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                          {row.vehicle.special_handlings &&
                          row.vehicle.special_handlings.length > 0 ? (
                            <Stack>
                              {row.vehicle.special_handlings.map((handling) => (
                                <HoverPopper holder={<InfoChip label={handling.title} />}>
                                  <Box
                                    sx={{
                                      width: '500px',
                                      textAlign: 'center',
                                      backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                      pointerEvents: 'auto',
                                      paddingTop: 1,
                                      paddingLeft: 3,
                                      paddingRight: 3,
                                      paddingBottom: 1,
                                    }}
                                  >
                                    {' '}
                                    {handling.description}
                                  </Box>
                                </HoverPopper>
                              ))}
                            </Stack>
                          ) : (
                            'N/A'
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : ''}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
