import { sanitizeNumber } from '../../../../components/helper/helper'
import { Offer } from '../../../../models/offer_new'

export function getInitialTax(offer: Offer): '' | number {
  if (offer.new_tax !== null && offer.new_tax !== undefined) {
    return sanitizeNumber(offer.new_tax)
  }
  return ''
}

export function getInitialShopSupplyFees(offer: Offer): '' | number {
  if (offer.new_supply_fee !== null && offer.new_supply_fee !== undefined) {
    return sanitizeNumber(offer.new_supply_fee)
  }
  return ''
}
