import { Group, NumberInput, Space, Text, TextInput } from '@mantine/core'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import DeleteIcon from '../../../../../assets/images/delete.svg'
import { ReactComponent as Plus } from '../../../../../assets/images/plus.svg'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import { APP_FONT } from '../../../../../constants/app_font'
import { useGetParentService } from '../../../../../hooks/useGetParentService'
import { ProviderRequestServiceAdditionalDataType } from '../../../../../models/offer_new'
import { Vehicle } from '../../../../../models/service_request'
import { Axles } from '../inspection_upload/selects/Axles'
import { CrankingAmps } from '../inspection_upload/selects/CrankingAmps'
import { TireInfo } from '../inspection_upload/selects/TireInfo'
import { TirePositions } from '../inspection_upload/selects/TirePositions'
import { RecommendedServicePart } from '../service.model'
import { ServiceFormErrors } from '../service.validation'

const AdditionalDataInfo = ({ label, value }) => {
  return (
    <Box display="flex" mt="5px">
      <Typography
        variant="body1"
        style={{
          fontWeight: 'regular',
          fontFamily: APP_FONT,
          fontSize: 14,
          marginRight: '5px',
        }}
      >
        {label}:
      </Typography>
      <Typography
        variant="body1"
        style={{ fontWeight: 'bold', fontFamily: APP_FONT, fontSize: 14 }}
      >
        {value}
      </Typography>
    </Box>
  )
}

export type OfferBlockService = {
  id: string
  name: string
  type: string | null
  types: any[] | null
  price: number
  serviceType: string
  labor_hours: string | number
  labor_price: string | number
  labor_rate: number
  parts: RecommendedServicePart[]
  request_additional_data: ProviderRequestServiceAdditionalDataType | null
  additional_data: ProviderRequestServiceAdditionalDataType | null
  vehicle: Vehicle
}

interface OfferBlockProps {
  service: OfferBlockService
  index: number
  handleServiceChange: (
    index: number,
    field: keyof OfferBlockService | string,
    value: string | string[] | number
  ) => void
  handleServicePartChange: (
    index: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string | number
  ) => void
  handleAddServicePart: (serviceIndex: number) => void
  handleRemoveServicePart: (serviceIndex: number, partIndex: number) => void
  handleAdditionalDataChange: (
    index: number,
    field: string,
    value: string | string[] | number
  ) => void
  disableServiceChange?: boolean
  errors?: ServiceFormErrors
}

export const OfferServiceBlock: React.FC<OfferBlockProps> = ({
  service,
  index,
  handleServiceChange,
  handleServicePartChange,
  handleAddServicePart,
  handleRemoveServicePart,
  handleAdditionalDataChange,
  disableServiceChange,
  errors,
}) => {
  const { data: parentService } = useGetParentService(service?.type || service?.id)
  // TODO: is this just the same as getting the service itself?
  const serviceChild = useMemo(() => parentService?.child, [parentService])

  const offerRequiredData = useMemo(() => {
    return serviceChild?.offer_required_data || parentService?.offer_required_data || []
  }, [serviceChild, parentService])

  const { IS_TIRE_POSITIONS, IS_TIRE_BRAND, IS_CRANKING_AMPS, IS_FRONT_AXLE, IS_REAR_AXLE } =
    useMemo(() => {
      return {
        IS_TIRE_POSITIONS: offerRequiredData.includes('TIRE_POSITIONS'),
        IS_TIRE_BRAND: offerRequiredData.includes('TIRE_BRAND'),
        IS_CRANKING_AMPS: offerRequiredData.includes('COLD_CRANKING_AMPS'),
        IS_FRONT_AXLE: offerRequiredData.includes('LF_PAD') && offerRequiredData.includes('RF_PAD'),
        IS_REAR_AXLE: offerRequiredData.includes('LR_PAD') && offerRequiredData.includes('RR_PAD'),
      }
    }, [offerRequiredData])

  const tireInfo = useMemo(() => {
    return service.vehicle?.tires
      ?.map((tire) => `${tire.width}/${tire.ratio}${tire.construction}${tire.diameter}`)
      .join(', ')
  }, [service.vehicle])

  const totalPrice =
    service.parts.reduce(
      (acc, part) => acc + sanitizeNumber(part.price_per_unit) * sanitizeNumber(part.quantity),
      0
    ) +
    sanitizeNumber(service.labor_hours) * service.labor_rate

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="start"
        mb={2}
        style={{
          backgroundColor: '#F9FAFB',
          padding: '12px',
          borderRadius: '8px',
          marginTop: '12px',
          marginBottom: '12px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          border: '1px solid #E5E7EB',
        }}
      >
        <Grid item xs={6}>
          <TextInput
            size="md"
            disabled={true}
            label="Service"
            value={service.name}
            styles={{ input: { color: 'black' } }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            label="Type"
            value={service.types?.find((type) => type.id === service.type)?.name || ''}
            size="md"
            disabled={true}
            styles={{ input: { color: 'black' } }}
          />
        </Grid>

        <Box
          sx={{
            padding: '0 16px',
            width: '100%',
          }}
        >
          {IS_TIRE_BRAND ? (
            <>
              <AdditionalDataInfo label="Tire size" value={tireInfo} />
              {Array.isArray(service.additional_data?.TIRE_POSITIONS) ? (
                <AdditionalDataInfo
                  label="# of tires to be replaced"
                  value={service.additional_data?.TIRE_POSITIONS.length}
                />
              ) : null}
              <AdditionalDataInfo
                label="Tires to be replaced"
                value={
                  Array.isArray(service.additional_data?.TIRE_POSITIONS)
                    ? service.additional_data?.TIRE_POSITIONS.join(', ')
                    : service.additional_data?.TIRE_POSITIONS || ''
                }
              />
              <Typography
                variant="body1"
                style={{
                  fontWeight: 'regular',
                  fontFamily: APP_FONT,
                  fontSize: 14,
                  marginRight: '5px',
                  marginTop: '5px',
                }}
              >
                {service.request_additional_data?.WARRANTY ? (
                  <>
                    Quote your lowest priced economy tire with tread warranty greater than or equal
                    to <strong>{service.request_additional_data.WARRANTY.toLocaleString()}</strong>{' '}
                    miles
                  </>
                ) : (
                  <>Quote your lowest priced economy tire</>
                )}
              </Typography>
            </>
          ) : null}
          {IS_TIRE_POSITIONS ? (
            <TirePositions
              tirePositions={service.additional_data?.TIRE_POSITIONS || []}
              values={{
                LF_MEASUREMENT: service.additional_data?.LF_MEASUREMENT || '',
                LR_MEASUREMENT: service.additional_data?.LR_MEASUREMENT || '',
                RF_MEASUREMENT: service.additional_data?.RF_MEASUREMENT || '',
                RR_MEASUREMENT: service.additional_data?.RR_MEASUREMENT || '',
                LR_INSIDE_MEASUREMENT: service.additional_data?.LR_INSIDE_MEASUREMENT || '',
                RR_INSIDE_MEASUREMENT: service.additional_data?.RR_INSIDE_MEASUREMENT || '',
              }}
              onChange={(field, value) => {
                handleAdditionalDataChange(index, field, value)
              }}
              onTirePositionsChange={(value) => {
                handleAdditionalDataChange(index, 'TIRE_POSITIONS', value)
              }}
            />
          ) : null}
          {IS_TIRE_BRAND ? (
            <TireInfo
              values={{
                TIRE_BRAND:
                  typeof service.additional_data?.TIRE_BRAND === 'object'
                    ? // @ts-ignore
                      service.additional_data?.TIRE_BRAND?.name || ''
                    : service.additional_data?.TIRE_BRAND || '',
                TIRE_MODEL: service.additional_data?.TIRE_MODEL ?? '',
                WARRANTY: service.additional_data?.WARRANTY || 0,
              }}
              onChange={({ field, value }) => {
                handleAdditionalDataChange(index, field, value)
              }}
              errors={{
                TIRE_BRAND: errors?.additional_data?.TIRE_BRAND?.message,
                TIRE_MODEL: errors?.additional_data?.TIRE_MODEL?.message,
                WARRANTY: errors?.additional_data?.WARRANTY?.message,
              }}
            />
          ) : null}
          {IS_CRANKING_AMPS ? (
            <CrankingAmps
              values={{
                // @ts-ignore
                COLD_CRANKING_AMPS: service.additional_data?.COLD_CRANKING_AMPS,
                // @ts-ignore
                FACTORY_COLD_CRANKING_AMPS: service.additional_data?.FACTORY_COLD_CRANKING_AMPS,
              }}
              onChange={(field, value) => {
                handleAdditionalDataChange(index, field, value)
              }}
            />
          ) : null}
          <Axles
            values={{
              // @ts-ignore
              LF_PAD: service.additional_data?.LF_PAD,
              // @ts-ignore
              LR_PAD: service.additional_data?.LR_PAD,
              // @ts-ignore
              RF_PAD: service.additional_data?.RF_PAD,
              // @ts-ignore
              RR_PAD: service.additional_data?.RR_PAD,
            }}
            onChange={(field, value) => {
              handleAdditionalDataChange(index, field, value)
            }}
            isFront={IS_FRONT_AXLE}
            isRear={IS_REAR_AXLE}
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '40px',
              marginBottom: '20px',
              gap: '10px',
            }}
          >
            <Text ff={APP_FONT} size="lg" c="black" fw={700}>
              Labor:
            </Text>
            <Group style={{ flexGrow: 1 }} align={'start'} justify={'end'} gap={12} wrap="nowrap">
              <NumberInput
                label={'Labor hours'}
                size="md"
                disabled={true}
                hideControls
                decimalScale={2}
                value={service.labor_hours}
                suffix=" hours"
                styles={{ input: { color: 'black' } }}
              />
              <NumberInput
                label={'Labor rate'}
                size="md"
                disabled={true}
                hideControls
                prefix={'$'}
                decimalScale={2}
                value={service.labor_rate}
                suffix="/hour"
                styles={{ input: { color: 'black' } }}
              />
              <NumberInput
                disabled={disableServiceChange}
                size="md"
                label="Labor cost"
                value={service.labor_price}
                w={175}
                decimalScale={2}
                hideControls
                prefix="$"
                min={0}
                max={100000}
                maxLength={14}
                onChange={(input) => {
                  let laborCost = parseFloat(input?.toString() || '0')
                  let laborHours = laborCost / service.labor_rate

                  handleServiceChange(index, 'labor_hours', laborHours)
                  handleServiceChange(index, 'labor_price', input)
                }}
                error={errors?.labor_price?.message}
                styles={{
                  input: {
                    textAlign: 'right',
                  },
                }}
              />
            </Group>
          </Box>

          <Box sx={{ marginTop: '12px' }}>
            <Text ff={APP_FONT} size="lg" c="black" fw={700} mt="20px" mb="8px">
              Parts:
            </Text>

            {service.parts.length > 0 &&
              service.parts.map((part, partIndex) => (
                <Box key={partIndex}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      gap: '16px',
                    }}
                  >
                    <TextInput
                      label="Part name"
                      placeholder="Enter part name"
                      size="md"
                      value={part.name}
                      onChange={(e) => {
                        handleServicePartChange(index, partIndex, 'name', e.target.value)
                      }}
                      flex={3}
                      miw={150}
                      error={errors?.parts[partIndex]?.name?.message}
                    />
                    <NumberInput
                      error={errors?.parts[partIndex]?.quantity?.message}
                      size="md"
                      label="Quantity"
                      value={part.quantity}
                      decimalScale={0} // the db only accepts whole numbers
                      min={1}
                      max={10000}
                      maxLength={14}
                      onChange={(input) =>
                        handleServicePartChange(index, partIndex, 'quantity', input)
                      }
                      flex={1}
                      miw={100}
                    />
                    <NumberInput
                      error={errors?.parts[partIndex]?.price_per_unit?.message}
                      size="md"
                      label="Price per unit"
                      value={part.price_per_unit}
                      decimalScale={2}
                      hideControls
                      prefix="$"
                      min={0}
                      max={100000}
                      maxLength={14}
                      onChange={(input) => {
                        handleServicePartChange(index, partIndex, 'price_per_unit', input)
                      }}
                      flex={1}
                      miw={150}
                    />
                    <TextInput
                      disabled={true}
                      size="md"
                      label="Part cost"
                      value={formatPrice(
                        sanitizeNumber(part.price_per_unit) * sanitizeNumber(part.quantity)
                      )}
                      styles={{ input: { color: 'black', textAlign: 'right' } }}
                      flex={1}
                      miw={150}
                    />
                    <IconButton
                      onClick={() => handleRemoveServicePart(index, partIndex)}
                      size="large"
                    >
                      <img src={DeleteIcon} alt="Delete" />
                    </IconButton>
                  </Box>
                  <Space h="xs" />
                  <TextInput
                    error={errors?.parts[partIndex]?.number?.message}
                    size="md"
                    label="Part number"
                    placeholder="Enter part number"
                    value={part.number}
                    onChange={(e) =>
                      handleServicePartChange(index, partIndex, 'number', e.target.value)
                    }
                    style={{ width: '30%' }}
                  />
                  <Space h="xs" />
                </Box>
              ))}
          </Box>
          {!disableServiceChange && (
            <Button
              startIcon={<Plus />}
              variant="text"
              color="primary"
              onClick={() => handleAddServicePart(index)}
              sx={{
                fontSize: '14px',
                textTransform: 'none',
                color: '#FF6600',
                fontWeight: 'bold',
                padding: 0,
                paddingLeft: 1,
                margin: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <span style={{ marginLeft: '10px', margin: 2, padding: 2, alignItems: 'center' }}>
                Add part
              </span>
            </Button>
          )}
          <Box
            display="flex"
            mt="12px"
            style={{
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontWeight: '600',
                fontFamily: APP_FONT,
                fontSize: 16,
                marginRight: '16px',
                color: '#111827',
              }}
            >
              Service total:
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontWeight: '600',
                fontFamily: APP_FONT,
                fontSize: 16,
                color: '#111827',
              }}
            >
              {formatPrice(totalPrice)}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  )
}
