import { Button, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ModalTableHeadShort from './components/table_head_short'
import { useState } from 'react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  width: 700,
  maxHeight: '90vh',
  overflowY: 'auto',
}

export default function InspectionWithoutServicesModal(props: any) {
  const { isOpen, offer, onClose, onConfirmWithoutService } = props
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = () => {
    setIsLoading(true)
    onConfirmWithoutService()
  }

  const handleClose = () => {
    setIsLoading(false)
    onClose()
  }
  return (
    <Modal open={isOpen} onClose={() => handleClose()}>
      <Box sx={style}>
        <ModalTableHeadShort
          onHide={() => handleClose()}
          service_request={{
            slug: offer.slug,
            vehicle: offer.vehicle ?? offer.consumer_request?.vehicle,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'right',
            alignItems: 'right',
            width: '100%',
            mb: 2,
            pb: 4,
            pt: 4,
            pl: 4,
            pr: 4,
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontFamily: 'Figtree',
              fontWeight: 'bold',
              color: '#FF6600',
              mb: 1,
            }}
          >
            Continue without recommending additional services?
          </Typography>
          <Typography
            sx={{
              fontSize: '18px',
              fontFamily: 'Figtree',
              fontWeight: '500',
              mb: 2,
              mr: 4,
            }}
          >
            You won’t be able to recommend any additional services anymore.
          </Typography>
          <Box component="span" display="flex" justifyContent="center" marginTop={'22px'}>
            <Button
              onClick={() => {
                handleClose()
              }}
              sx={{ width: 150, height: 40, borderRadius: '20px', mr: 5 }}
              variant="outlined"
              color="warning"
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontFamily: 'Figtree',
                  fontWeight: 'bold',
                }}
              >
                Go back
              </Typography>
            </Button>
            <Button
              onClick={() => {
                handleConfirm()
              }}
              sx={{ width: 150, height: 40, borderRadius: '20px' }}
              variant="contained"
              color="warning"
              disabled={isLoading}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontFamily: 'Figtree',
                  fontWeight: 'bold',
                }}
              >
                Confirm
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
